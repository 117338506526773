import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { OrderService } from 'src/app/services/order.service';
import { OrderErpExportStatus, OrderState } from 'src/app/models/orders.models';
import { ShipmentState, ShipmentStatus } from 'src/app/models/shipments.models';
import { ILoginUserSession } from 'src/app/models/users.models';
import { AuthorizationService } from 'src/app/services/authurization.service';
import { AdminService } from 'src/app/services/admin.service';
import { Store } from '@ngrx/store';
import { statisticsStateFeature } from 'src/app/state/statistics.reducer';
import { config } from 'process';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
import { ReportedStoresObjects } from 'src/app/models/stores';

@Component({
  selector: 'nav-menu',
  templateUrl: './rightnav.menu.usercontrol.component.html',
  styleUrls: ['./rightnav.menu.usercontrol.component.css']
})
export class RightNavMenuUserControlComponent implements OnInit {

  public currentUrl!: string;
  public ActivePage!: string;
  public DraftOrderCount$ = this.store.select(statisticsStateFeature.selectDraftOrderCount);
  public InvalidShipmentOrderCount$ = this.store.select(statisticsStateFeature.selectInvalidShipmentOrderCount);

  public StockModuleEnable$ = this.store.select(ConfigStateFeature.stockModuleEnable).pipe(filter(b => typeof b === "boolean"));
  public OutOfStockOrderCount$ = this.store.select(statisticsStateFeature.selectOutOfStockOrderCount);


  constructor(private router: Router, private _adminService: AdminService, private _orderService: OrderService, private _authorizationService: AuthorizationService, private store: Store) {

  }
  ngOnInit(): void {

    this.router.events.pipe(
      filter(e => e instanceof RouterEvent && (this.currentUrl != (e as RouterEvent).url)),
      map(e => (e as RouterEvent).url)
    ).subscribe(url => {
      this.updateCurrentUrl(url)
    });
    this.updateCurrentUrl(this.router.url);



  }

  private updateCurrentUrl(newUrl: string) {
    this.currentUrl = newUrl;
    var newActivePage = this.updateActivePage();
    this.ActivePage = newActivePage;
  }
  public isRegexMatchUrl(regexPattern: string): boolean {
    return new RegExp(regexPattern).test(this.currentUrl);
  }
  private updateActivePage() {
    if (this.isRegexMatchUrl('\\/orders\\/?\\?.*((ShipmentStatus=' + ShipmentStatus.New + '&?)|(OrderState=' + OrderState.Active + '&?)){2}')) return 'OrdersNewActive';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*((ERPExportStatus=' + OrderErpExportStatus.Ready + '&?)|(OrderState=' + OrderState.Active + '&?)){2}')) return 'OrdersActiveReadyForErp';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*((CollectStatus=' + OrderErpExportStatus.Ready + '&?)|(OrderState=' + OrderState.Active + '&?)){2}')) return 'OrdersActiveReadyForCollect';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*OrderState=' + OrderState.Draft + '&?')) return 'OrdersDratf';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*(ShipmentStatus=(2000|3000|3500|4000|9000)&?){5}') && this.isRegexMatchUrl('\\/orders.*OrderState=1') && this.isRegexMatchUrl('\\/orders.*HasShipmentTrackingNumber=true')) return 'OrdersActiveReadyReport';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*ShipmentState=' + ShipmentState.IgnoreValidation + '&?')) return 'OrdersWithShipmentErrors';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*OrderState=' + OrderState.OutOfStock + '&?')) return 'OrdersOutOfStock';
    else if (this.isRegexMatchUrl('\\/order\\/new')) return 'NewOrder';
    else if (this.isRegexMatchUrl('\\/import-orders-upload')) return 'ImportOrders';
    else if (this.isRegexMatchUrl('\\/orders-wizard')) return 'Orderswizard';
    else if (this.isRegexMatchUrl('\\/orders\\/?\\?.*300103.*')) return 'OrdersProductPriceExceed';
    else if (this.isRegexMatchUrl('\\/orders-shipment-merger')) return "OrderShipmentMerger";
    else if (this.isRegexMatchUrl('\\/products(\\/.*)?$')) return 'Products';
    else if (this.isRegexMatchUrl('\\/import-products-upload(\\/.*)?$')) return 'ProductsImports';
    else if (this.isRegexMatchUrl('\\/import-stock-upload(\\/.*)?$')) return 'StocksImports';
    else if (this.isRegexMatchUrl('\\/admin\\/store')) return 'AdminStore';
    else if (this.isRegexMatchUrl('\\/admin\\/shipment')) return 'AdminShipment';
    else if (this.isRegexMatchUrl('\\/admin\\/custom-export')) return 'AdminCustomExport';
    else if (this.isRegexMatchUrl('\\/admin\\/supplier')) return 'AdminSupplier';
    else if (this.isRegexMatchUrl('\\/orders\\/?(\\?.*)?')) return 'Orders';
    else if (this.isRegexMatchUrl('\\product-catalog.*')) return 'ProductCatalog';
  }

  public CreateQueryForReadyForReport() {
    return {
      ShipmentStatus: [2000, 3000, 3500, 4000, 9000],
      OrderState: [1],
      OriginStore: ReportedStoresObjects.map(r => r.Name),
      HasShipmentTrackingNumber: true
    };
  }

  public HasRole(roles: string[]): boolean {
    return this._authorizationService.DoseUserHasRole(roles);
  }

  public get DevelopMode(): boolean {
    return window.location.hostname.toLocaleLowerCase() === "localhost";
  }
  public get StagingMode(): boolean {
    return window.location.hostname.toLocaleLowerCase() === "stage.sende.co.il";
  }
}
