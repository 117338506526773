import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ViewChild, AfterContentChecked, AfterViewInit } from '@angular/core';

import { IShipment, DeliveryShipmentsTypes, ShipmentState } from '../../../models/shipments.models';


import { UserControlEditorMode } from '../../../models/user-controls';

import { AbstractControl, FormControlStatus, UntypedFormGroup } from '@angular/forms';



@Component({
  selector: 'shipment-editor',
  templateUrl: './shipment.editor.usercontrol.component.html',
  styleUrls: ['./shipment.editor.usercontrol.component.css']
})
export class ShipmentEditorUserControlComponent implements OnInit, OnChanges {
  constructor(public cd: ChangeDetectorRef) { }
  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();
  @Output() ShipmentChange: EventEmitter<IShipment> = new EventEmitter<IShipment>();
  @Input() Shipment!: IShipment;
  @Input() Mode!: UserControlEditorMode;

  public ValidationFrom: UntypedFormGroup;

  public Controls = {
    DeliveryEditor: {
      Enable: false
    },
    DropPointEditor: {
      Enable: false
    }
  }


  ngOnInit(): void {
    if (!this.Mode) this.Mode = UserControlEditorMode.View;
    this.ValidationFrom = new UntypedFormGroup({});
    this.RefreshControls();

    this.ValidationFrom.statusChanges.subscribe(status => {
      if (this.IgnoreValidation) this.OnValidChange.emit('VALID');
      else {
        this.OnValidChange.emit(status as FormControlStatus);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.Shipment) {
      this.RefreshControls();
    }
  }

  public BindFromControl(controlName: string, control: AbstractControl) {
    if (this.ValidationFrom && this.ValidationFrom.controls[controlName]) this.ValidationFrom.removeControl(controlName);
    this.ValidationFrom.addControl(controlName, control);
  }

  public RefreshControls() {

    this.Controls.DropPointEditor.Enable = (this.Shipment.ItemType == 'BoxItShipment' || this.Shipment.ItemType == 'ChitaShopsShipment' || this.Shipment.ItemType == 'YDMShopsShipment' || this.Shipment.ItemType == 'SosnaShopsShipment' || this.Shipment.ItemType == 'EPostShipment' || this.Shipment.ItemType == 'UPSPickUpShipment' || this.Shipment.ItemType == 'OrionShopsShipment');
    this.Controls.DeliveryEditor.Enable = (!this.Controls.DropPointEditor.Enable) && (DeliveryShipmentsTypes.indexOf(this.Shipment.ItemType) > -1);

    if (this.ValidationFrom) {

      if (!this.Controls.DeliveryEditor.Enable) this.ValidationFrom.removeControl("DeliveryEditor");
      if (!this.Controls.DropPointEditor.Enable) this.ValidationFrom.removeControl("DropPointEditor");

    }

  }

  public ResetError() {
    this.Shipment.ErrorMessage = null;
    this.Shipment.State &= (~ShipmentState.SubmitError);
    this.ShipmentChange.emit(this.Shipment);
  }

  get IgnoreValidation(): boolean {
    return (this.Shipment.State & ShipmentState.IgnoreValidation) > 0;
  }
  set IgnoreValidation(ignore: boolean) {

    if (ignore) {
      this.Shipment.State = this.Shipment.State | ShipmentState.IgnoreValidation;
    }
    else {
      this.Shipment.State = this.Shipment.State & (~ShipmentState.IgnoreValidation);
    }
    this.ValidationFrom.updateValueAndValidity({ emitEvent: true });


  }




}

